/* underwriters */
.contenedor {
    margin-right: auto;
    margin-left: 1%;
    padding-left: 60px;
    padding-right: 60px;
}
.underwriters-box a, .underwriters-box div {
    display: inline;
    font-size: 0;
    height: 150px;
    margin: 0 auto;
    padding: 0;
    text-indent: -9999px;
    text-shadow: none;
    width: auto;
    margin-bottom: 9%;
}

.underwriters-box > div > img, .underwriters-box > a > img
{
    filter: grayscale(100%);
    height: auto !important;
}
.underwriters-box > div > img:hover, .underwriters-box > a > img:hover
{
    filter: grayscale(0);
}

.underwriters-geico-marine {
    background: url("../../public/img/underwriters/geico_marine.webp") no-repeat scroll center top / 229px auto rgba(0, 0, 0, 0);
}

.underwriters-geico-marine:hover {
    background: url("../../public/img/underwriters/geico_marine_hover.webp") no-repeat scroll center top / 229px auto rgba(0, 0, 0, 0);
}

.underwriters-seaworthy {
    background: url("../../public/img/underwriters/seaworthy.webp") no-repeat scroll center top / 229px auto rgba(0, 0, 0, 0);
}

.underwriters-seaworthy:hover {
    background: url("../../public/img/underwriters/seaworthy_hover.webp") no-repeat scroll center top / 229px auto rgba(0, 0, 0, 0);
}

.underwriters-concept_special_risks {
    background: url("../../public/img/underwriters/concept_special_risks.webp") no-repeat scroll center top / 229px auto rgba(0, 0, 0, 0);
}

.underwriters-concept_special_risks:hover {
    background: url("../../public/img/underwriters/concept_special_risks_hover.webp") no-repeat scroll center top / 229px auto rgba(0, 0, 0, 0);
}

.underwriters-lloyds_lloyds {
    background: url("../../public/img/underwriters/lloyds_lloyds.webp") no-repeat scroll center top / 229px auto rgba(0, 0, 0, 0);
}

.underwriters-lloyds_lloyds:hover {
    background: url("../../public/img/underwriters/lloyds_lloyds_hover.webp") no-repeat scroll center top / 229px auto rgba(0, 0, 0, 0);
}
